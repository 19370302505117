/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import axios from "axios";
import { Button, Form, Input, Radio, message, Modal, Result } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation, Trans } from "react-i18next";
import { withTranslation } from "react-i18next";
import i18n from "../locales/config";

import Banner0 from "./Banner0";
import Content1 from "./Content1";
import Content0 from "./Content0";
import Content3 from "./Content3";
import Footer0 from "./Footer0";

import {
  Banner00DataSource,
  Content10DataSource,
  Content00DataSource,
  Content30DataSource,
  Footer00DataSource,
} from "./data.source";
import "./less/antMotionStyle.less";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== "undefined" ? window : {};

let formRef = React.createRef();

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      modalVisible: false,
      saving: false,
      traceId: undefined,
    };
  }

  onFormFinish = (values) => {
    this.setState({ saving: true });
    axios.post("/api/console/wechat/home/support", values).then((resp) => {
      if (resp && resp.data) {
        this.setState({ traceId: resp.data.data, saving: false });
      }
    });
  };

  closeSupportModal() {
    formRef.current.resetFields();
    this.setState({ modalVisible: false, saving: false, traceId: undefined });
  }

  showModal() {
    window.location.href = "mailto:wechatconnector@gmail.com";
    // this.setState({ modalVisible: true });
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const { t } = this.props;
    const children = [
      <Banner0
        id="Banner0_0"
        key="Banner0_0"
        dataSource={Banner00DataSource}
        isMobile={this.state.isMobile}
        showmodal={() => {
          this.showModal();
        }}
      />,
      <Content1
        id="Content1_0"
        key="Content1_0"
        dataSource={Content10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content0
        id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content3
        id="Content3_0"
        key="Content3_0"
        dataSource={Content30DataSource}
        isMobile={this.state.isMobile}
      />,
      <div style={{textAlign: "center", height: "100px"}}>
        <div>Contact Us: <a href="mailto:wechatconnector@gmail.com">wechatconnector@gmail.com</a>
        </div>
      </div>,
      <Footer0
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
        <Modal
          onCancel={this.closeSupportModal.bind(this)}
          style={{ minWidth: 600 }}
          title={t("pages.help.supportMessageTitle")}
          centered
          destroyOnClose
          visible={this.state.modalVisible}
          footer={[
            <Button
              key="cancel"
              hidden={this.state.traceId != undefined}
              onClick={() => {
                this.closeSupportModal();
              }}
            >
              <Trans>common.cancel</Trans>
            </Button>,
            <Button
              key="submit"
              hidden={this.state.traceId !== undefined}
              type="primary"
              loading={this.state.saving}
              onClick={() => {
                formRef.current.submit();
              }}
            >
              <Trans>common.save</Trans>
            </Button>,
            <Button
              key="close"
              hidden={this.state.traceId === undefined}
              type="primary"
              onClick={() => {
                this.closeSupportModal();
              }}
            >
              <Trans>common.close</Trans>
            </Button>,
          ]}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            ref={formRef}
            name="control-hooks"
            onFinish={this.onFormFinish}
            hidden={this.state.traceId != undefined}
          >
            <Form.Item
              name="title"
              label={t("pages.help.form.title")}
              rules={[{ required: true }]}
              initialValue=""
            >
              <Input maxLength={200} />
            </Form.Item>
            <Form.Item
              name="content"
              label={t("pages.help.form.content")}
              initialValue=""
            >
              <TextArea rows={6} maxLength={600} />
            </Form.Item>
            <Form.Item
              name="name"
              label={t("pages.help.form.name")}
              initialValue=""
              rules={[{ required: true }]}
            >
              <Input maxLength={100} />
            </Form.Item>
            <Form.Item
              name="company"
              label={t("pages.help.form.company")}
              initialValue=""
              rules={[{ required: true }]}
            >
              <Input maxLength={100} />
            </Form.Item>
            <Form.Item
              name="contact"
              label={t("pages.help.form.contact")}
              initialValue=""
              rules={[{ required: true }]}
            >
              <Input maxLength={200} />
            </Form.Item>
            <Form.Item
              name="eloquaUser"
              label={t("pages.help.form.eloquaUser")}
              initialValue=""
              rules={[{ required: true }]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="yes">
                  <Trans>pages.help.form.isEloquaUser</Trans>
                </Radio.Button>
                <Radio.Button value="no">
                  <Trans>pages.help.form.notEloquaUser</Trans>
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {/* <Form.Item
            name="attachment"
            label={intl.formatMessage({
              id: 'pages.help.form.attachment',
              defaultMessage: '附件',
            })}
          >
            <Upload>
              <Button icon={<UploadOutlined />}>
                <FormattedMessage id="pages.help.form.upload" defaultMessage="上传" />
              </Button>
            </Upload>
          </Form.Item> */}
          </Form>
          <Result
            style={{
              display: this.state.traceId == undefined ? "none" : "block",
            }}
            status="success"
            title={t("pages.help.form.successMsg1") + this.state.traceId}
            subTitle={t("pages.help.form.successMsg2")}
          />
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(Home);
