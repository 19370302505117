import React from 'react';
export const Banner00DataSource = {
  wrapper: { className: 'banner0 l4sn6lhhhxs-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper l4su9uk1zxs-editor_css' },
  title: {
    className: 'banner0-title l4sn70lz54s-editor_css',
    children: 'https://www.wechat-connector.com/static/image/wc-logo-w.png',
  },
  content: {
    className: 'banner0-content',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>A WeChat integration solution for Eloqua</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  button: { className: 'banner0-button', children: 'Contact Us' },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://www.wechat-connector.com/static/image/2023_features_ui.png',
    className: 'l4t4hfo45f-editor_css',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>Eloqua WeChat Connector 2023</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <p>
                                                      Eloqua-WeChat Integrator 
                                                      solution is designed for 
                                                      connect Eloqua with WeChat. It supports the
                                                      whole marketing flow from
                                                      Landing Page to Campaign
                                                      and External Activities.
                                                      By the deep integration
                                                      with Eloqua App Framework,
                                                      it can automatically
                                                      import WeChat data into
                                                      Eloqua to maxmize Eloqua
                                                      abilities in WeChat
                                                      marketing. Contact us for
                                                      more features and
                                                      scenarios.
                                                    </p>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>Key Features</p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <p>Acquisition</p>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <p>
                                                                      Integrated
                                                                      with
                                                                      Eloqua
                                                                      Landing
                                                                      Page to
                                                                      acquire
                                                                      WeChat
                                                                      subscriber
                                                                      profiles.
                                                                      Support to
                                                                      different
                                                                      kinds of
                                                                      Eloqua
                                                                      Landing
                                                                      Pages and
                                                                      user
                                                                      information.
                                                                    </p>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <p>
                                                                      Promotion
                                                                    </p>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <p>
                                                                  Send WeChat
                                                                  messages from
                                                                  Eloqua
                                                                  campaign with
                                                                  segmented
                                                                  contacts.
                                                                  Collect WeChat
                                                                  user behavior
                                                                  into Eloqua
                                                                  External
                                                                  Activity for
                                                                  further
                                                                  analysis.
                                                                </p>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <p>Analysis</p>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <p>
                                              Use cutting-edge business
                                              intelligence technology to provide
                                              reports in various aspects.
                                              Dashboards help customers stay on
                                              top of the situation.
                                            </p>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Professional Services</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    Powered by our social marketing experts and world-class
                    cloud providers
                  </p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>
                                Full Marketing Lifecycle<br />
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                    WeChat Connector is designed to support full Eloqua marketing lifecycle
                    with a wide variety of features.
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p> Social Marketing Specialist</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Development team has 10 years social integration experiences
                  cross products and platforms.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Follow WeChat Changes</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Support Draft Message, Published Message and keep update to
                  adopt new features from WeChat platform.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Security</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <p>
                                  All data transfers are authenticated and
                                  encrypted. Data in block storage is fully encrypted 
                                  and backed up regularly. Sensitive data is encrypted by AES 256 and the password is secured by hardware security modules which meet 140-2 Security Level 3 security certification.
                                </p>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>Privacy</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <p>
                                      User data is stored in Eloqua and WeChat
                                      servers, and the application retains only
                                      minimal data to support functional
                                      operation and analysis needs. Customers
                                      can completely delete user data in application 
                                      servers. Our&nbsp;Our Frankfurt data center
                                      can fulfill GDPR requirements.
                                    </p>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>Price</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <p>
                                                                  WeChat Connector is now completely free. We guarantee to offer more competitive prices than other companies in the future. Any price changes will be announced six months in advance for existing customers.&nbsp;&nbsp;
                                                                  </p>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                © 2022 - 2023&nbsp;<a href="https://www.wechat-connector.com/">
                  WeChat Connector
                </a>&nbsp;All Rights Reserved.&nbsp;
              </span>Message icons created by{' '}
              <a href="https://www.flaticon.com/free-icons/message">Freepik</a>{' '}
              - Flaticon. Eloqua is an Oracle product.
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
